import React, { useEffect, useRef, useState } from 'react';
import { WithWizard } from 'react-albus-react18';
import CustomTitle from '../components/UI/title/CustomTitle';
import { gsap } from 'gsap';

const ChooseVideo = ({ creativeId, setCreativeId, setCreativeType, muteStatus, setMuteStatus, setButtonShow, setButtonChoose, setBlockPaddingBottom, setButtonStatus, setAiShow, setButtonTime, imgCV, videoCV, textCV1, textCV2, setEventProperties, sessionId, setEventName, eventName, }) => {
    useEffect(() => {
        setButtonShow(false);
        setButtonChoose(true);
        setCreativeId(creativeId)
        setCreativeType('video')
        if (setBlockPaddingBottom) {
            setBlockPaddingBottom('32rem')
        }
        if (setButtonStatus) {
            setButtonStatus('')
        }
        if (setAiShow) {
            setAiShow('')
        }

        if (setButtonTime) {
            setButtonTime(0)
        }

        if (videoRef.current) {
            videoRef.current.muted = muteStatus;
        }
        setEventName(eventName)
        setEventProperties({
            session_id: sessionId,
            web_entrance: 'signup',
        })
    }, []);

    const videoRef = useRef(null);


    const muteChange = () => {
        if (videoRef.current) {
            setMuteStatus(!videoRef.current.muted)
            videoRef.current.muted = !videoRef.current.muted;
        }
    };

    const [loading, setLoading] = useState(true);

    const handleLoadedData = () => {
        setLoading(false);
    };

    return (
        <div style={{ paddingBottom: "32rem" }}>
            <CustomTitle style={{ marginBottom: "12rem" }} title="Do you like this video ad?" />
            <p style={{ marginBottom: "24rem" }}  className="text">We’ll add this format to your plan</p>
            <div className="choose-video-item" onClick={muteChange} style={{ aspectRatio: "193/343", margin: "0 auto 0rem",  position: 'relative', borderRadius: '12rem', overflow: 'hidden' }}>

                {loading && (
                    <div className="skeleton-loader">
                        {/* Skeleton loader */}
                        <img style={{ width: '100%' }} src={process.env.PUBLIC_URL + `/img/${imgCV}`} alt="" />
                    </div>
                )}
                <video
                    ref={videoRef}
                    style={{ width: '100%', borderRadius: '12rem' }}

                    autoPlay
                    loop
                    muted
                    playsInline
                    onLoadedData={handleLoadedData}
                >
                    <source src={process.env.PUBLIC_URL + `/video/${videoCV}`} type="video/mp4" />
                </video>



                <div style={{ position: 'absolute', width: '32rem', height: '32rem', bottom: '10rem', right: '10rem' }}>
                    {muteStatus ? (
                        <div style={{ width: '32rem', height: '32rem', backdropFilter: "blur(8px)", background: "rgba(0, 0, 0, 0.32)", borderRadius: '50%', display: 'flex', alignItems: "center", justifyContent: 'center' }}>
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="icon sound_off">
                                    <g id="Group 12343338">
                                        <path id="Vector 235" d="M8.80469 4.67773C8.80469 4.67773 9.83695 5.45193 9.83695 7.00031C9.83695 8.5487 8.80469 9.3229 8.80469 9.3229" stroke="white" stroke-width="1.39355" stroke-linecap="round" />
                                        <path id="Vector 236" d="M11.1289 3.64648C11.1289 3.64648 12.1612 4.88005 12.1612 7.00132C12.1612 9.12259 11.1289 10.3562 11.1289 10.3562" stroke="white" stroke-width="1.39355" stroke-linecap="round" />
                                        <g id="Subtract">
                                            <path d="M2.74929 3.90305L6.99723 8.15099V2.05232C6.99723 1.5925 6.44128 1.36222 6.11614 1.68736L3.90045 3.90305H2.74929Z" fill="white" />
                                            <path d="M6.99723 9.97578L2.01216 4.99071C1.94713 4.92568 1.83594 4.97173 1.83594 5.0637V9.68369C1.83594 9.91173 2.0208 10.0966 2.24884 10.0966H3.90045L6.11614 12.3123C6.44128 12.6374 6.99723 12.4071 6.99723 11.9473V9.97578Z" fill="white" />
                                        </g>
                                        <path id="Vector 238" d="M1.83594 2.35547L11.3843 11.9039" stroke="white" stroke-width="1.39355" stroke-linecap="round" />
                                    </g>
                                </g>
                            </svg>

                        </div>
                    ) : (
                        <div style={{ width: '32rem', height: '32rem', backdropFilter: "blur(8px)", background: "rgba(0, 0, 0, 0.32)",  borderRadius: '50%', display: 'flex', alignItems: "center", justifyContent: 'center' }}>

                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="icon sound_on">
                                    <g id="Group 12343338">
                                        <path id="Rectangle 4734" d="M1.83594 4.31632C1.83594 4.08828 2.0208 3.90341 2.24884 3.90341H3.90045L6.11614 1.68773C6.44128 1.36258 6.99723 1.59286 6.99723 2.05269V11.9477C6.99723 12.4075 6.44128 12.6378 6.11614 12.3126L3.90045 10.097H2.24884C2.0208 10.097 1.83594 9.9121 1.83594 9.68406V4.31632Z" fill="white" />
                                        <path id="Vector 235" d="M8.80469 4.67773C8.80469 4.67773 9.83695 5.45193 9.83695 7.00031C9.83695 8.5487 8.80469 9.3229 8.80469 9.3229" stroke="white" stroke-width="1.29032" stroke-linecap="round" />
                                        <path id="Vector 236" d="M11.125 3.64453C11.125 3.64453 12.1573 4.8781 12.1573 6.99937C12.1573 9.12064 11.125 10.3542 11.125 10.3542" stroke="white" stroke-width="1.29032" stroke-linecap="round" />
                                    </g>
                                </g>
                            </svg>
                        </div>


                    )}
                </div>
                <p className='choose-video-bottom-text'>{textCV2}</p>
            </div>

            {/* <img src={process.env.PUBLIC_URL + `/img/${imgCV}`} style={{ borderRadius: '12rem',width: '100%' }} alt="" /> */}

            {/* <p className='choose-video-top-text'>{textCV1}</p> */}
           

        </div>
    );
}

export default ChooseVideo;
