import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './Appv1';
import './styles/App.scss';
import './styles/media.scss';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div className="container " >
    <App / >
  </div>
);
