import React, {useEffect} from 'react';
import { WithWizard } from 'react-albus-react18';
import CustomTitle from '../components/UI/title/CustomTitle';
import { gsap }from 'gsap';

const Choose4 = ({setCreativeId, setButtonShow, setButtonChoose, setButtonStatus, setBlockPaddingBottom, setAiShow,  setCreativeType,  setEventProperties, sessionId, setEventName }) => {
    useEffect(() => {
        setCreativeType('static');
        setCreativeId('861a67df-5c53-4ec6-802d-a79620957a0f')
        setButtonShow(false);
        setButtonChoose(true);
        setButtonStatus('')
        setBlockPaddingBottom('32rem')
        setAiShow(false)
        setEventName('web_format_4')
        setEventProperties({
            session_id: sessionId,
            web_entrance: 'signup',
        })
    }, []);
    return (
        <div style={{ paddingBottom: "32rem" }}>
            <CustomTitle title="Do you like this creative?" />
            <p style={{ marginBottom: "24rem" }} className="text">We’ll add this format to your plan</p>
            <div style={{ aspectRatio: "40/51", margin: "0 auto 0rem", maxWidth: '200rem' }}>
                <img src={process.env.PUBLIC_URL + '/img/choose4.jpg'} style={{ borderRadius: '12rem' }} alt="" />
            </div>
            
            </div>
    );
}

export default Choose4;
